import Axios, { AxiosResponse } from 'axios';
import CustomerSettings from './customerSettings';
import Cookies from 'js-cookie';
import  api from '../../../share/api';
import { db_Order_v2 } from '../../../share/db';

const customerSettings: CustomerSettings = new CustomerSettings();

export const getAllOrders = async (params?: api.getAllOrderParams) : Promise<api.getAllOrderResult> => {

    const getAllOrdersResp: AxiosResponse<api.getAllOrderResult> = await Axios({
        method: 'get',
        url: customerSettings.backend_api_url + '/v1/get-all-order',
        params: {...params},
        headers: { Authorization: Cookies.get('authHeaderValue')},
    });
    return getAllOrdersResp.data;
}

export const getOrderApi = async (orderId: string) : Promise<api.getOrderResult> => {
    
    const getOrderResponse: AxiosResponse<api.getOrderResult> =
            await Axios({
              method: 'get',
              url:
                customerSettings.backend_api_url +
                '/v1/get-order?orderId=' +
                orderId,
            });

    return getOrderResponse.data;

}

export const putOrder = async (order: db_Order_v2) : Promise<api.putOrderResult> => {
    // Add new Order in DB and get ID
    const putOrderResponse: AxiosResponse<api.putOrderResult> = await Axios({
        method: 'post',
        url: customerSettings.backend_api_url + '/v1/put-order',
        headers: {
            Authorization: Cookies.get('authHeaderValue'),
        },
        data: order,
    });

    return putOrderResponse.data;
}

export const updateOrderApi = async (order: db_Order_v2) : Promise<api.updateOrderResult> => {
    const updateResponseOrder : AxiosResponse<api.updateOrderResult> = await Axios({
        method: 'post',
        url: customerSettings.backend_api_url + '/v1/update-order',
        headers: {
          Authorization: Cookies.get('authHeaderValue'),
        },
        data: order,
    });

    return updateResponseOrder.data;
}