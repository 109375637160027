import React, { MutableRefObject, useEffect, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import ButtonPrimary from '../../components/Button';
import TextInputField from '../../components/TextInputField';
import NumberInputField from '../../components/NumberInputField';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import HomeButton from '../../components/HomeButton';
import { db_Order_v2 } from '../../../../share/db';

import CustomerSettings from '../../util/customerSettings';
import api from '../../../../share/api';
import useCheckLogin from '../../util/useCheckLogin';
import { FrontendOrder, UseOrdersResponse } from '../../util/useOrders';
import { create } from 'domain';

const customerSetting: CustomerSettings = new CustomerSettings();

interface NewOrderProps {
  useOrders: UseOrdersResponse;
}

const NewOrder = ({useOrders}: NewOrderProps) => {
  const [order, setOrder] = React.useState<FrontendOrder>({
    clientName: '',
    amount: 0,
    comment: '',
    status: 'open',
    paymentReference: '',
    language: customerSetting.destination_langs?.[0].id,
    stripeRedirectUrl: customerSetting.destination_langs?.[0].base_url,
    stripeProductId: customerSetting.stripe_product_id,
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [canBeSubmitted, setCanBeSubmitted] = React.useState<boolean>(false);
  const [newOrderCreated, setNewOrderCreated] = React.useState<boolean>(false);

  const refToClientLinkInput  = useRef<HTMLInputElement>(null);

  const checkLogin = useCheckLogin();

  useEffect(() => {
    checkLogin();
  }, []);

  // Check if the order can be submitted
  useEffect(() => {
    if (
      order.amount &&
      order.amount > 0 &&
      order.clientName &&
      order.clientName.length > 0
    ) {
      setCanBeSubmitted(true);
    } else {
      setCanBeSubmitted(false);
    }
  }, [order]);


  const onClientName = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.clientName = event.currentTarget.value;
    setOrder(newOrder);
  };

  const onAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    if (event.currentTarget.value.length > 0) {
      if (typeof parseInt(event.currentTarget.value) === 'number') {
        newOrder.amount = parseFloat(event.currentTarget.value);
        event.currentTarget.value = newOrder.amount + '';
      }
    } else {
      newOrder.amount = 0;
    }
    setOrder(newOrder);
  };
  const onComment = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.comment = event.currentTarget.value;
    setOrder(newOrder);
  };

  const onLang = (event: React.FormEvent<HTMLSelectElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.language = event.currentTarget.value;
    newOrder.stripeRedirectUrl = customerSetting.getDestinationLangWithId(
      newOrder.language,
    )?.base_url;
    setOrder(newOrder);
  };


  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    setNewOrderCreated(true);
    setIsLoading(true);

    const orderResponse = await useOrders.createNewOrder({ ...order });

    setOrder(orderResponse);

    const clientLinkTextArea: HTMLInputElement =
      refToClientLinkInput.current ?? new HTMLInputElement();
    clientLinkTextArea.select();
    navigator.clipboard.writeText(clientLinkTextArea.value)
    //document.execCommand('copy'); Deprecated

    setIsLoading(false);
  };

  
    const langOption = customerSetting.destination_langs?.map((lang) => {
      return (
        <option key={lang.id} value={lang.id}>
          {lang.name}
        </option>
      );
    });

    return (
      <>
        <HomeButton />
        <h1 className="title is-1">Create new order</h1>

        <TextInputField
          name="Client Name"
          placeholder="Max Mustermann"
          onChange={onClientName}
          value={order.clientName}
          active={!newOrderCreated}
        />
        <NumberInputField
          name="Amount in € (what the client has to pay)"
          placeholder="5.23"
          onChange={onAmount}
          value={order.amount}
          active={!newOrderCreated}
        />
        <TextInputField
          name="Comment (internal)"
          placeholder="Vine etc."
          onChange={onComment}
          value={order.comment}
          active={!newOrderCreated}
        />

        <div className="field">
          <label className="label">Language</label>
          <div className="control">
            <div className="select">
              <select
                value={order.language}
                onChange={onLang}
                disabled={newOrderCreated}
              >
                {langOption}
              </select>
            </div>
          </div>
        </div>

        <br />

        {order.clientLink?.length ?? -1 > 0 ? (
          <div className="field">
            <label className="label">Client Link (copied to clipboard)</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={order.clientLink}
                ref={refToClientLinkInput}
                readOnly
              />
            </div>
          </div>
        ) : null}

        {newOrderCreated ? null : (
          <ButtonPrimary
            text="Submit"
            active={canBeSubmitted}
            onClick={onSubmit}
          />
        )}
        <LoadingAnimation isLoading={isLoading} />
      </>
    );
  }


export default NewOrder;
