import React, { ReactElement } from 'react';

interface ButtonProps {
  text: string;
  onClick: any;
  active: boolean;
  type?: string;
  isLight?: boolean;
  isLoading?: boolean;
  isOutlined?: boolean;
}

const Button: React.FC<ButtonProps> = (
  props: ButtonProps,
): ReactElement => {

  const type = props.type || 'primary';
  const lightString = props.isLight ? 'is-light' : '';
  const loadingString = props.isLoading ? 'is-loading' : '';
  const outlinedString = props.isOutlined ? 'is-outlined' : '';

  return (
    <div className="control">
      <button
        onClick={props.onClick}
        disabled={!props.active}
        className={`button is-${type} ${lightString} ${loadingString} ${outlinedString}`}
      >
        {props.text}
      </button>
    </div>
  );
};

export default Button;
