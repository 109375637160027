import React, { useEffect, useState, useDeferredValue } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { db_Order_v2 } from '../../../../share/db';

import { OrderList } from '../../components/OrderList';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import Button from '../../components/Button';
import FilterForm from './FilterForm';
import { UseOrdersResponse } from '../../util/useOrders';
import IconModal from '../../components/IconModal';

interface DashboardProps {
  useOrders: UseOrdersResponse;
}

const Dashboard: React.FC<DashboardProps> = ({useOrders}:DashboardProps) => {
  const [ordersDisplay, setOrdersDisplay] = useState<db_Order_v2[]>([]);;
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterState, setFilterState] = useState<string>('all');
  
  const {orders, loading : useOrdersLoading, init, onlyRelevantOrders, loadMostRelevantOrders, updating, loadAllOrders, loadUpdatedOrders, lastUpdateXMinutesAgo} = useOrders;

  const isLoading = useOrdersLoading;

  const deferredFilterValue = useDeferredValue(filterValue);

  const navigate = useNavigate();

  useEffect(() => {
      if (!Cookies.get('authHeaderValue')) {
        navigate('/login'); 
      } else {
        init();
      }
  }, []);

  useEffect(() => {
    setOrdersDisplay(orders);
  }, [useOrdersLoading, orders]);

  const clickToNewOrder = () => {
    navigate('/neworder');
  };

  const clickOnOrder = (order: db_Order_v2) => {
    return (event: React.MouseEvent<HTMLTableRowElement>) => {
      navigate('/order?orderId=' + order.id);
    };
  };

  const filterStringOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.currentTarget.value);
  };

  const filterStateOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterState(event.currentTarget.value);
  };

  // Apply filter
  useEffect(() => {
    console.log('Filtering...');

    const filteredOrders: db_Order_v2[] = [];

    for (let order of orders) {
      let stringFilterResult: boolean = false;
      if (deferredFilterValue.length > 0) {
        if (
          order.paymentReference?.includes(deferredFilterValue) ||
          order.clientName?.toLowerCase().includes(deferredFilterValue.toLowerCase())
        ) {
          stringFilterResult = true;
        }
      } else {
        stringFilterResult = true;
      }

      let stateFilterResult: boolean = false;
      if (filterState === 'all') {
        stateFilterResult = true;
      } else {
        if (order.status === filterState) {
          stateFilterResult = true;
        }
      }

      if (stringFilterResult && stateFilterResult) {
        filteredOrders.push({...order})
      }
    }

    setOrdersDisplay(filteredOrders);
  }, [deferredFilterValue, filterState, orders]);

  return (
    <>
      <div className='block'>
        <h1 className="title is-1">Order Dashboard</h1>
        <Button
          text="Create new order"
          active={true}
          onClick={clickToNewOrder}
        />
      </div>
      <div className='block'>
        <h2 className="title is-2">Order List</h2>
        <div className="columns  is-vcentered">
          <div className="column is-four-fifths">
            <Button
              text={`Update orders, last update ${lastUpdateXMinutesAgo} minutes ago`}
              type='info'
              active={!isLoading && !updating}
              isLoading={updating}
              onClick={() => {loadUpdatedOrders()}}
            />
          </div>
          <div className="column">
            <div className='columns'>
              <Button
                text={onlyRelevantOrders ? 'Switch to all orders mode' : 'Switch to relevant orders mode'}
                type={onlyRelevantOrders ? 'warning' : 'success'}
                isLight={true}
                active={!isLoading}
                onClick={() => {onlyRelevantOrders ? loadAllOrders() : loadMostRelevantOrders()}}
              />
              <IconModal icon='fa-question-circle' modalHeader={<h1>Help</h1>}>
                <p>
                By default, only orders that have changed in the last 3 months will be shown. This is sufficient most of the time. For clarity, even an order created 6 months ago and paid for now by the customer will be shown, as its status changed within the last 3 months. If you want to load the entire order history, click the button to load all orders.
                </p>
                <p>
                The filter options can only show orders that are loaded. This means if you have not loaded the entire order history, you will only find orders that changed within the last 3 months. If you want to search for older orders, you need to load all orders before searching.
                </p>
              </IconModal>
            </div>
          </div>
        </div>
        <FilterForm
          filterString={filterValue}
          filterState={filterState}
          onStringChange={filterStringOnChange}
          onStateChange={filterStateOnChange}
          active={!isLoading}
        />
        <LoadingAnimation isLoading={isLoading} />
        <OrderList orders={ordersDisplay} onOrderClick={clickOnOrder} />
      </div>
    </>
  );
};

export default Dashboard;
