import React from 'react';

interface ModalProps {
    children: React.ReactNode;
    modalHeader: React.ReactNode;
    isActive: boolean;
    onClose: any;
}

const Modal = ({children, modalHeader, isActive, onClose}:ModalProps) => {
    const isActiveString = isActive ? 'is-active' : '';
    return (
        <div className={`modal ${isActiveString}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{modalHeader}</p>
                    <button className="delete" aria-label="close" onClick={onClose}></button>
                </header>
                <section className="modal-card-body">
                    {children}
                </section>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
        </div>
    );
};

export default Modal;