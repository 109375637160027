import React from 'react';

import { db_Order_v2 } from '../../../../share/db';

interface PropsOrderList {
  orders: db_Order_v2[];
  onOrderClick: any;
}

const timestampToLocalString = (timestamp: number | undefined): string => {
  if (timestamp && timestamp > 0) {
    return new Date(timestamp).toLocaleString();
  }
  return '';
};

export const OrderList = (props: PropsOrderList) => {
  const orderList = props.orders.map((order: db_Order_v2, _) => {
    return (
      <tr
        className={'clickable ' + order.status}
        key={order.id}
        onClick={props.onOrderClick(order)}
      >
        <td>{order.paymentReference}</td>
        <td>{order.clientName}</td>
        <td>{order.amount?.toFixed(2) + ' €'}</td>
        <td>{order.status}</td>
        <td>{timestampToLocalString(order.date)}</td>
        <td>{timestampToLocalString(order.payDate)}</td>
        <td>{timestampToLocalString(order.shipDate)}</td>
        <td>{order.comment}</td>
      </tr>
    );
  });
  return (
    <div className='block'>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Payment Reference</th>
            <th>Client Name</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Order Date</th>
            <th>Pay Date</th>
            <th>Ship Date</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {orderList.length > 0 ? (
            orderList
          ) : (
            <tr>
              <td>No Orders</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
