import React, { useEffect } from 'react';
import { db_Order_v2 } from '../../../share/db';
import api from '../../../share/api';
import { getAllOrders, getOrderApi, putOrder, updateOrderApi } from './apiClient';
import CustomerSettings from './customerSettings';

export interface FrontendOrder extends db_Order_v2 {
  clientLink?: string;
}

const customerSetting: CustomerSettings = new CustomerSettings();

const frontendOrderFromDbOrder = (dbOrder: db_Order_v2): FrontendOrder => {
  const clientLink = customerSetting.getDestinationLangWithId(dbOrder.language ?? 'de')?.base_url + '?orderId=' + dbOrder.id;
  return {
    ...dbOrder,
    clientLink
  };
}

/**
 * Inplace sort orders by date, newest first
 * @param orders 
 * @returns A reference to the input array
 */
const sortOrdersNewestFirst = (orders: FrontendOrder[]): FrontendOrder[] => {
  return orders.sort((a: FrontendOrder, b: FrontendOrder): number => {
    let d_a = a.date ?? 0;
    let d_b = b.date ?? 0;
    return d_b - d_a;
  });
};

export interface UseOrdersResponse {
  orders: FrontendOrder[];
  loading: boolean;
  lastUpdate: number;
  updating: boolean;
  lastUpdateXMinutesAgo: number;
  onlyRelevantOrders: boolean;
  init: () => void;
  loadUpdatedOrders: () => void;
  loadMostRelevantOrders: () => void;
  loadAllOrders: () => void;
  createNewOrder: (newOrder: db_Order_v2) => Promise<FrontendOrder>;
  updateOrder: (order: db_Order_v2) => Promise<FrontendOrder>;
  getOrder: (orderId: string) => Promise<FrontendOrder>;
}

const useOrders = () : UseOrdersResponse => {
  // Cached orders
  const [ordersState, setOrdersState] = React.useState<FrontendOrder[]>([]);
  // It is important that internal functions only read from ordersRef.current and not ordersState directly, to prevent reading from stale state.
  const ordersRef = React.useRef(ordersState);
  
  const [active, setActive] = React.useState<boolean>(false);
  const [onlyRelevantOrders, setOnlyRelevantOrders] = React.useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = React.useState<number>(0);
  const [lastUpdateXMinutesAgo, setLastUpdateXMinutesAgo] = React.useState<number>(0);

  // Loading indicates that the orders are currently being loaded, loading is more interuptive than updating.
  const [loading, setLoading] = React.useState<boolean>(false);

  // Updateting indicates that the orders are currently being updated.
  const [updating, setUpdating] = React.useState<boolean>(false);

  // This state is used to allways show how many minutes ago the last update was done.
  useEffect(() => {
    const calculateMinutesAfterTime = (time: number): number => {
      return Math.trunc((new Date().getTime() - time) / 1000 / 60);
    }
    
    setLastUpdateXMinutesAgo(calculateMinutesAfterTime(lastUpdate));
    const interval = setInterval(() => {
      setLastUpdateXMinutesAgo(calculateMinutesAfterTime(lastUpdate));
    }, 60000);
    return () => clearInterval(interval);
  }, [lastUpdate]);

  // Update orders every x seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if(active) {
        loadUpdatedOrders();
      }
    }, 15 * 60000);
    return () => clearInterval(interval);
  }, [active]);

  // Update ordersRef
  // Must be used to read order state internally in order to avoid to read an outdated state.
  useEffect(() => {
    ordersRef.current = ordersState;
  }, [ordersState]);

  const loadUpdatedOrders = async () => {

    setUpdating(true);

    const getUpdatedOrdersResp: api.getAllOrderResult = await getAllOrders({orModifiedLaterThan: lastUpdate});

    if (getUpdatedOrdersResp.Order.length > 0) {

      const newOrders = [...ordersRef.current];

      for(let i = 0; i < getUpdatedOrdersResp.Order.length; i++) {
        const newOrUpdatedOrder = getUpdatedOrdersResp.Order[i];

        const oldVersionOrder = newOrders.find((order) => order.id === newOrUpdatedOrder.id);

        if (oldVersionOrder) {
          newOrders[newOrders.indexOf(oldVersionOrder)] = frontendOrderFromDbOrder(newOrUpdatedOrder);
        } else {
          newOrders.push(frontendOrderFromDbOrder(newOrUpdatedOrder));
        }
      }

      sortOrdersNewestFirst(newOrders);
      setOrdersState(newOrders);
    }

    setLastUpdate(new Date().getTime());
    setUpdating(false);
  }

  const createNewOrder = async (newOrder: db_Order_v2) : Promise<FrontendOrder> => {

    await init();

    const orderReturnedByDb: db_Order_v2 = (await putOrder(newOrder)).Order;

    const newFrontendOrder = frontendOrderFromDbOrder(orderReturnedByDb);

    const newOrders = [...ordersRef.current];
    newOrders.push(newFrontendOrder);

    sortOrdersNewestFirst(newOrders);
    setOrdersState(newOrders);

    return newFrontendOrder;
  }

  const getOrder = async (orderId: string) : Promise<FrontendOrder> => {

    await init();

    const getOrderResponse : db_Order_v2 = (await getOrderApi(orderId)).Order;

    const indexOfOldOrder = ordersRef.current.findIndex((oldOrder) => oldOrder.id === getOrderResponse.id);

    if(indexOfOldOrder === -1) {
      const newOrders = [...ordersRef.current];
      newOrders.push(frontendOrderFromDbOrder(getOrderResponse));
      setOrdersState(newOrders);
    } else {
      const newOrders = [...ordersRef.current];
      newOrders[indexOfOldOrder] = frontendOrderFromDbOrder(getOrderResponse);
      setOrdersState(newOrders);
    }

    return frontendOrderFromDbOrder(getOrderResponse);
  }

  const updateOrder = async (order: db_Order_v2) : Promise<FrontendOrder> => {

    await init();

    const updateResponseOrder : db_Order_v2 = (await updateOrderApi(order)).Order;

    const updatedFrontendOrder = frontendOrderFromDbOrder(updateResponseOrder);

    const newOrders = [...ordersRef.current];
    const indexOfOldOrder = newOrders.findIndex((oldOrder) => oldOrder.id === updateResponseOrder.id);
    newOrders[indexOfOldOrder] = updatedFrontendOrder;

    setOrdersState(newOrders);

    return updatedFrontendOrder;
  }

  const loadAllOrders = async () => {

    setLoading(true);
    setLastUpdate(new Date().getTime());

    const getAllOrdersResp: api.getAllOrderResult = await getAllOrders();

    if (getAllOrdersResp.Order.length > 0) {

      const newOrders = [...getAllOrdersResp.Order];
      const newFrontendOrders = newOrders.map((order) => frontendOrderFromDbOrder(order));
      sortOrdersNewestFirst(newFrontendOrders);
      setOrdersState(newFrontendOrders);
    }
    
    setOnlyRelevantOrders(false);
    setLoading(false);
  }

  const loadMostRelevantOrders = async () => {

    setLoading(true);
    setLastUpdate(new Date().getTime());

    // Last 3 months
    const modifiedLaterThanDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3;

    const getMostRelevantOrdersResp: api.getAllOrderResult = await getAllOrders({orModifiedLaterThan: modifiedLaterThanDate});

    if (getMostRelevantOrdersResp.Order.length > 0) {

      const newOrders = [...getMostRelevantOrdersResp.Order]
      const newFrontendOrders = newOrders.map((order) => frontendOrderFromDbOrder(order));
      sortOrdersNewestFirst(newFrontendOrders);
      setOrdersState(newFrontendOrders);
    }
    
    setOnlyRelevantOrders(true);
    setLoading(false);
  };

  const init = async () => {
    if(!active) {
      setActive(true);
      await loadMostRelevantOrders();
    }
  }

  return {orders : ordersState, loading, lastUpdate, updating, lastUpdateXMinutesAgo, onlyRelevantOrders, init, loadMostRelevantOrders, loadUpdatedOrders, loadAllOrders, createNewOrder, updateOrder, getOrder};
}

export default useOrders;