import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const LoginChecker: React.FC = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get('authHeaderValue')) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <h1>Check if logged in and redirect</h1>
    </>
  );
};

export default LoginChecker;
