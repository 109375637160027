import React, { useState } from 'react';
import Modal from '../Modal';

interface IconModalProps {
    children: React.ReactNode;
    modalHeader: React.ReactNode;
    icon: string;
}

const IconModal = ({children, modalHeader, icon} : IconModalProps) => {
    const [isActive, setIsActive] = useState(false);

    const toggleModal = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <span className="icon" onClick={toggleModal}>
                <i className={`fas ${icon}`}></i>
            </span>
            <Modal modalHeader={modalHeader} isActive={isActive} onClose={toggleModal}>
                {children}
            </Modal>
        </>
    );
}

export default IconModal;