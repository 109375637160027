import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";

import Dashboard from '../Dashboard';
import Login from '../Login';
import LoginChecker from '../LoginChecker';
import NewOrder from '../NewOrder';
import Order from '../Order';
import useOrders from '../../util/useOrders';

const App: React.FC = () => {
  console.info('Nanopay - v1.0.0');

  const useOrdersResp = useOrders();

  const router = createBrowserRouter([
    {
      path: "/",
      element:<LoginChecker/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/order",
      element: <Order useOrders={useOrdersResp}/>,
    },
    {
      path: "/dashboard",
      element: <Dashboard useOrders={useOrdersResp}/>,
    },
    {
      path: "/neworder",
      element: <NewOrder useOrders={useOrdersResp} />,
    },
  ]);
  return (
      <RouterProvider router={router}/>
  );
};

export default App;


/*
    <Router>
      <Route component={LoginChecker} path="/" exact />
      <Route component={Login} path="/login" />
      <Route component={Order} path="/order" />
      <Route component={Dashboard} path="/dashboard" />
      <Route component={NewOrder} path="/neworder" />
    </Router>
    */