import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { SHA3 } from 'sha3';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import api from '../../../../share/api';

import LoginForm from '../../components/LoginForm';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import CustomerSettings from '../../util/customerSettings';

const Login: React.FC = () => {
  const [us, setUs] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const usHandleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setUs(event.currentTarget.value);
  };

  const pwHandleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPw(event.currentTarget.value);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isLoading) {
      setIsLoading(true);

      const username: string = us.slice(0);
      const password: string = pw.slice(0);

      const hasher = new SHA3(224);
      hasher.update(password);
      const pwHash: string = hasher.digest('hex');

      const authHeaderValue = 'Basic ' + btoa(username + ':' + pwHash);

      const customerSettings: CustomerSettings = new CustomerSettings();
      const authUserResponse: AxiosResponse<api.authUserResult> = await axios({
        method: 'get',
        url: customerSettings.backend_api_url + '/v1/auth-user',
        headers: { Authorization: authHeaderValue },
      });

      setIsLoading(false);

      if (!authUserResponse.data.Authorized) {
        setErrorMessage('Wrong username or password!');
        setUs('');
        setPw('');
      } else {
        setErrorMessage('Ok');
        Cookies.set('authHeaderValue', authHeaderValue);
        navigate('/dashboard');
      }
    }
  };

  return (
    <>
      <h1 className="title is-1">Login</h1>
      <LoadingAnimation isLoading={isLoading} />
      <ErrorMessage message={errorMessage} />
      <LoginForm
        usValue={us}
        usOnChange={usHandleChange}
        pwValue={pw}
        pwOnChange={pwHandleChange}
        handleSubmit={handleFormSubmit}
      />
    </>
  );
};

export default Login;
