import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db_Order_v2 } from '../../../../share/db';
import Cookies from 'js-cookie';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import TextInputField from '../../components/TextInputField';
import ReadOnlyInputField from '../../components/ReadOnlyInputField';
import ButtonPrimary from '../../components/Button';
import HomeButton from '../../components/HomeButton';
import { FrontendOrder, UseOrdersResponse } from '../../util/useOrders';

const timestampToLocalString = (timestamp: number | undefined): string => {
  if (timestamp && timestamp > 0) {
    return new Date(timestamp).toLocaleString();
  }
  return '';
};

interface OrderProps {
  useOrders: UseOrdersResponse;
}

const Order = ({useOrders}:OrderProps) => {

  const [order, setOrder] = React.useState<FrontendOrder>({
    id: '',
    clientName: '',
    amount: 0,
    comment: '',
    shippingLink: '',
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [canBeSubmitted, setCanBeSubmitted] = React.useState<boolean>(false);
  const [changeStateOnSaveTo, setChangeStateOnSaveTo] =
    React.useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (Cookies.get('authHeaderValue')) {
        const url = new URL(window.location.href);

        const orderId = url.searchParams.get('orderId');
        if(orderId === null) {
          throw new Error('no order id');
        }

        let respondedOrder : db_Order_v2 = await useOrders.getOrder(orderId);

        setChangeStateOnSaveTo(respondedOrder.status ?? '');
        setOrder(respondedOrder);
        
      } else {
        navigate('/login');
      }
      setIsLoading(false);
    };
    loadData();
  }, []);

  // Check if the order can be submitted
  useEffect(() => {
    if (order.clientName && order.clientName.length > 0) {
      setCanBeSubmitted(true);
    } else {
      setCanBeSubmitted(false);
    }
  }, [order]);

  const onClientName = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.clientName = event.currentTarget.value;
    setOrder(newOrder);
  };

  const onComment = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.comment = event.currentTarget.value;
    setOrder(newOrder);
  };

  const onShippingLink = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order_v2 = { ...order };
    newOrder.shippingLink = event.currentTarget.value;
    setOrder(newOrder);
  };

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeStateOnSaveTo(event.currentTarget.value);
  };

  const onSave = async (event: React.ChangeEvent<HTMLButtonElement>) => {
    setIsLoading(true);

    const newOrder: db_Order_v2 = { ...order };
    newOrder.status = changeStateOnSaveTo;
    if (order.status === 'open' && changeStateOnSaveTo === 'paid') {
      newOrder.payDate = Date.now();
    }
    if (order.status === 'paid' && changeStateOnSaveTo === 'shipped') {
      newOrder.shipDate = Date.now();
    }
    if (order.status === 'open' && changeStateOnSaveTo === 'shipped') {
      newOrder.payDate = Date.now();
      newOrder.shipDate = Date.now();
    }

    const updatedOrderResp = await useOrders.updateOrder(newOrder);

    setOrder(updatedOrderResp);
    setIsLoading(false);
  };

  return (
    <>
      <HomeButton />
      <h1 className="title is-1">Edit Order</h1>
      <LoadingAnimation isLoading={isLoading} />
      <ReadOnlyInputField
        name="Payment Reference"
        value={order.paymentReference}
      />
      <ReadOnlyInputField name="Client Pay Link" value={order.clientLink} />

      <TextInputField
        name="Client Name"
        placeholder="Max Mustermann"
        onChange={onClientName}
        value={order.clientName}
        active={!isLoading}
      />
      <ReadOnlyInputField
        name="Amount in €"
        value={order.amount?.toFixed(2) + ' €'}
      />
      <TextInputField
        name="Comment (internal)"
        placeholder="Vine order xy"
        onChange={onComment}
        value={order.comment}
        active={!isLoading}
      />

      <ReadOnlyInputField
        name="Order Date"
        value={timestampToLocalString(order.date)}
      />

      <ReadOnlyInputField
        name="Pay Date"
        value={timestampToLocalString(order.payDate)}
      />

      <ReadOnlyInputField
        name="Ship Date"
        value={timestampToLocalString(order.shipDate)}
      />

      <TextInputField
        name="Shipping Link"
        placeholder="https://www.dhl.de/....."
        onChange={onShippingLink}
        value={order.shippingLink}
        active={!isLoading}
      />

      <div className="radios">
        {order.status === 'open' ? (
          <label className="radio">
            <input
              onChange={handleRadio}
              checked={changeStateOnSaveTo === 'open'}
              type="radio"
              name="open"
              value={order.status}
              disabled={isLoading}
            />
            Open
          </label>
        ) : null}
        {order.status === 'open' || order.status === 'paid' ? (
          <label className="radio">
            <input
              onChange={handleRadio}
              checked={changeStateOnSaveTo === 'paid'}
              type="radio"
              name="none"
              value="paid"
              disabled={isLoading}
            />
            Paid
          </label>
        ) : null}
        {order.status === 'open' ||
        order.status === 'paid' ||
        order.status === 'shipped' ? (
          <label className="radio">
            <input
              onChange={handleRadio}
              checked={changeStateOnSaveTo === 'shipped'}
              type="radio"
              name="none"
              value="shipped"
              disabled={isLoading}
            />
            Shipped
          </label>
        ) : null}

        {order.status === 'open' ||
         order.status === 'cancelled' ? (
          <label className='radio'>
            <input
              onChange={handleRadio}
              checked={changeStateOnSaveTo === 'cancelled'}
              type="radio"
              name="none"
              value="cancelled"
              disabled={isLoading}
            />
            Cancelled
          </label>
        ): null}
      </div>
      {order.status === 'open' && changeStateOnSaveTo !== 'open' ? (
        <div className="notification is-warning is-light">
          By changing to this status the order can no longer be paid over credit
          card by the customer.
        </div>
      ) : null}
      <br></br>
      <ButtonPrimary
        text="Save"
        active={canBeSubmitted && !isLoading}
        onClick={onSave}
      />
      <LoadingAnimation isLoading={isLoading} />
    </>
  );
};

export default Order;
