import React from 'react';
import { useNavigate } from 'react-router-dom';

const styleI = {
  display: 'inline-block',
  marginLeft: '8px',
};

const HomeButton = () => {

  const navigate = useNavigate();

  const localTest = (e: any) => {
    navigate('/dashboard');
  };
  return (
    <span onClick={localTest} className="icon clickable">
      <i style={styleI} className="fas fa-long-arrow-alt-left">
        {' '}
      </i>
      <i style={styleI} className="fas fa-home">
        {' '}
      </i>
    </span>
  );
};

export default HomeButton;
