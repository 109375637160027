import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckLogin = () => {
  const navigate = useNavigate();

  return () => {
    if (Cookies.get('authHeaderValue')) {
    } else {
      navigate('/login');
    }
  };
};

export default useCheckLogin;
